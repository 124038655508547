import { Button, CheckBox, Control, Panel, Select, TextArea } from "@core/components/alt-ui/controls";
import { IGoodInventory, IGoodInventoryCreateDto, IStore } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";

export interface IGoodInventoryCreateModalContext {
    userId: string;
    stores: IStore[];
    storeId: string;

    /** Обработчик создания инвентаризации. */
    createHandler?: (dto: IGoodInventoryCreateDto) => Promise<IGoodInventory | null>;

    /** Обработчик заполнения инвентаризации. */
    populateHandler?: (inventory: IGoodInventory) => Promise<boolean>;
}

export class GoodInventoryCreateModal extends Modal<IGoodInventoryCreateModalContext, IGoodInventory | undefined> {
    private cbStore!: Select<IStore>;
    private taComment!: TextArea;
    private chbPopulate!: CheckBox;

    private btnCancel!: Button;
    private btnSave!: Button;
    private pnlFooter!: Panel;

    private context?: IGoodInventoryCreateModalContext;

    public constructor() {
        super("good-inventory-create-modal", "Инвентаризация");
        this.initializeControls();
    }

    public get controls(): Control[] {
        return [this.cbStore, this.taComment, this.chbPopulate, this.pnlFooter];
    }

    public async show(context: IGoodInventoryCreateModalContext): Promise<IGoodInventory | undefined> {
        this.context = context;
        this.initializeControls();
        return super.show();
    }

    private initializeControls(): void {
        this.cbStore = new Select<IStore>();
        this.cbStore.id = "good-inventory-create.store";
        this.cbStore.label = "Склад";
        this.cbStore.items = this.context?.stores ?? [];
        this.cbStore.textField = ac => ac.info.name;
        this.cbStore.descriptionField = ac => ac.info.description;
        this.cbStore.selectedItem =
            this.cbStore.items.find(o => o.id === this.context?.storeId) ??
            (this.cbStore.items.length > 0 ? this.cbStore.items[0] : null);

        this.taComment = new TextArea();
        this.taComment.id = "good-inventory-create.comment";
        this.taComment.label = "Комментарий";

        this.chbPopulate = new CheckBox();
        this.chbPopulate.id = "good-inventory-create.populate";
        this.chbPopulate.text = "Заполнить нулевыми значениями";
        this.chbPopulate.help = "Заполнить таблицу всеми товарами со склада с 0 количеством";
        this.chbPopulate.value = true;

        this.btnCancel = new Button();
        this.btnCancel.id = "good-inventory-create.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(() => this.clickCancel());

        this.btnSave = new Button();
        this.btnSave.id = "good-inventory-create.save";
        this.btnSave.text = "Сохранить";
        this.btnSave.addClickHandler(() => this.clickSave());

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControl(this.btnCancel);
        this.pnlFooter.addControl(this.btnSave);
    }

    private async clickCancel(): Promise<void> {
        this.hide();
    }

    private async clickSave(): Promise<void> {
        const valid = await this.validate();

        if (!valid || !this.context?.createHandler || !this.cbStore.selectedItem) {
            return;
        }

        const dto: IGoodInventoryCreateDto = {
            version: 2,
            // TODO: убрать этот массив вообще
            goods: [],
            storeId: this.cbStore.selectedItem.id,
            employeeId: this.context.userId,
            comment: this.taComment.text,
        };

        const inventory = await this.context.createHandler(dto);

        if (!inventory) {
            return;
        }

        if (this.chbPopulate.value && this.context?.populateHandler) {
            await this.context.populateHandler(inventory);
        }

        this.hide(inventory);
    }
}
